const apiErrorCodeList = [
  "unknownException",
  "notFoundAccount",
  "notFoundAggregation",
  "notFoundAssetEntry",
  "notFoundBattery",
  "notFoundBatteryInstall",
  "notFoundCapitalSource",
  "notFoundChart",
  "notFoundDashboard",
  "notFoundEngine",
  "notFoundEngineInstall",
  "notFoundEventCondition",
  "notFoundExternalUser",
  "notFoundExternalUserGrant",
  "notFoundGlobalAnnouncement",
  "notFoundHeatSource",
  "notFoundHeatSourceInstall",
  "notFoundHotwellInstall",
  "notFoundLevelisedCost",
  "notFoundLicence",
  "notFoundLicencePlan",
  "notFoundLoadProfileEntry",
  "notFoundInvite",
  "notFoundManufacturer",
  "notFoundOperationCriterion",
  "notFoundPendingMfaToken",
  "notFoundPermissionRole",
  "notFoundProfitLossEntry",
  "notFoundProject",
  "notFoundProjectGroup",
  "notFoundScenario",
  "notFoundSolarPanel",
  "notFoundSolarPanelInstall",
  "notFoundTotpToken",
  "notFoundUser",
  "notFoundUserGroup",
  "notFoundValueMap",
  "notFoundWindTurbine",
  "notFoundWindTurbineInstall",
  "invalidToken",
  "invalidSession",
  "invalidTotpCode",
  "invalidPasswordResetToken",
  "invalidUserInviteToken",
  "invalidExternalUserInviteToken",
  "mfaRequired",
  "notPermitted",
  "notPermittedSelfDelete",
  "notPermittedRequiredFlags",
  "noSelectedLoadProfile",
  "fileTooLarge",
  "alreadyExistsTotpToken",
  "alreadyExistsUser",
  "alreadyExistsInviteCrossAccount",
  "limitReadOnlySeatsInAccount",
  "limitReadWriteSeatsInAccount",
  "limitProfitLossEntriesInScenario",
  "limitProjectGroupsInAccount",
  "limitProjectsInAccount",
  "licenceDatesOverlap",
  "licencePlanDegraded",
  "licencePlanInUse",
  "permissionRoleNoWildcardAccountId",
  "permissionRoleReadWriteWithReadOnlyUsers",
  "userGroupReadWriteWithReadOnlyUsers",
  "closeUpStartBeforeEnd",
  "closeUpMinDays",
  "closeUpMaxDays",
  "valueMapEmpty",
  "valueMapWrongColumnCount",
  "valueMapRowWrongColumnCount",
  "valueMapRowInvalidDateFormat",
  "valueMapRowDateOutOfRange",
  "valueMapRowDuplicateDate",
  "valueMapRowDuplicateDayOfYear",
  "valueMapRowInvalidDecimal",
  "preprocessingDataNotReady",
  "preprocessingDataError",
  "notLoggedInExternalUserInvite",
  "totpTokenUsed",
  "accountIdMismatch",
] as const

export type ApiErrorCode = (typeof apiErrorCodeList)[number]

export const apiErrorCodes: Record<ApiErrorCode, ApiErrorCode> = {
  unknownException: "unknownException",

  notFoundAccount: "notFoundAccount",
  notFoundAggregation: "notFoundAggregation",
  notFoundAssetEntry: "notFoundAssetEntry",
  notFoundBattery: "notFoundBattery",
  notFoundBatteryInstall: "notFoundBatteryInstall",
  notFoundCapitalSource: "notFoundCapitalSource",
  notFoundChart: "notFoundChart",
  notFoundDashboard: "notFoundDashboard",
  notFoundEngine: "notFoundEngine",
  notFoundEngineInstall: "notFoundEngineInstall",
  notFoundEventCondition: "notFoundEventCondition",
  notFoundExternalUser: "notFoundExternalUser",
  notFoundExternalUserGrant: "notFoundExternalUserGrant",
  notFoundGlobalAnnouncement: "notFoundGlobalAnnouncement",
  notFoundHeatSource: "notFoundHeatSource",
  notFoundHeatSourceInstall: "notFoundHeatSourceInstall",
  notFoundHotwellInstall: "notFoundHotwellInstall",
  notFoundLevelisedCost: "notFoundLevelisedCost",
  notFoundLicence: "notFoundLicence",
  notFoundLicencePlan: "notFoundLicencePlan",
  notFoundLoadProfileEntry: "notFoundLoadProfileEntry",
  notFoundInvite: "notFoundInvite",
  notFoundManufacturer: "notFoundManufacturer",
  notFoundOperationCriterion: "notFoundOperationCriterion",
  notFoundPendingMfaToken: "notFoundPendingMfaToken",
  notFoundPermissionRole: "notFoundPermissionRole",
  notFoundProfitLossEntry: "notFoundProfitLossEntry",
  notFoundProject: "notFoundProject",
  notFoundProjectGroup: "notFoundProjectGroup",
  notFoundScenario: "notFoundScenario",
  notFoundSolarPanel: "notFoundSolarPanel",
  notFoundSolarPanelInstall: "notFoundSolarPanelInstall",
  notFoundTotpToken: "notFoundTotpToken",
  notFoundUser: "notFoundUser",
  notFoundUserGroup: "notFoundUserGroup",
  notFoundValueMap: "notFoundValueMap",
  notFoundWindTurbine: "notFoundWindTurbine",
  notFoundWindTurbineInstall: "notFoundWindTurbineInstall",

  invalidToken: "invalidToken",
  invalidSession: "invalidSession",
  invalidTotpCode: "invalidTotpCode",
  invalidPasswordResetToken: "invalidPasswordResetToken",
  invalidUserInviteToken: "invalidUserInviteToken",
  invalidExternalUserInviteToken: "invalidExternalUserInviteToken",

  notPermitted: "notPermitted",
  notPermittedSelfDelete: "notPermittedSelfDelete",
  notPermittedRequiredFlags: "notPermittedRequiredFlags",

  noSelectedLoadProfile: "noSelectedLoadProfile",
  fileTooLarge: "fileTooLarge",

  alreadyExistsTotpToken: "alreadyExistsTotpToken",
  alreadyExistsUser: "alreadyExistsUser",
  alreadyExistsInviteCrossAccount: "alreadyExistsInviteCrossAccount",

  limitReadOnlySeatsInAccount: "limitReadOnlySeatsInAccount",
  limitReadWriteSeatsInAccount: "limitReadWriteSeatsInAccount",
  limitProfitLossEntriesInScenario: "limitProfitLossEntriesInScenario",
  limitProjectGroupsInAccount: "limitProjectGroupsInAccount",
  limitProjectsInAccount: "limitProjectsInAccount",

  licenceDatesOverlap: "licenceDatesOverlap",
  licencePlanDegraded: "licencePlanDegraded",
  licencePlanInUse: "licencePlanInUse",

  permissionRoleNoWildcardAccountId: "permissionRoleNoWildcardAccountId",
  permissionRoleReadWriteWithReadOnlyUsers:
    "permissionRoleReadWriteWithReadOnlyUsers",

  userGroupReadWriteWithReadOnlyUsers: "userGroupReadWriteWithReadOnlyUsers",

  closeUpStartBeforeEnd: "closeUpStartBeforeEnd",
  closeUpMinDays: "closeUpMinDays",
  closeUpMaxDays: "closeUpMaxDays",

  valueMapEmpty: "valueMapEmpty",
  valueMapWrongColumnCount: "valueMapWrongColumnCount",
  valueMapRowWrongColumnCount: "valueMapRowWrongColumnCount",
  valueMapRowInvalidDateFormat: "valueMapRowInvalidDateFormat",
  valueMapRowDateOutOfRange: "valueMapRowDateOutOfRange",
  valueMapRowDuplicateDate: "valueMapRowDuplicateDate",
  valueMapRowDuplicateDayOfYear: "valueMapRowDuplicateDayOfYear",
  valueMapRowInvalidDecimal: "valueMapRowInvalidDecimal",

  preprocessingDataError: "preprocessingDataError",
  preprocessingDataNotReady: "preprocessingDataNotReady",

  notLoggedInExternalUserInvite: "notLoggedInExternalUserInvite",

  totpTokenUsed: "totpTokenUsed",

  accountIdMismatch: "accountIdMismatch",

  mfaRequired: "mfaRequired",
}
