import enGB from "./en-gb.json"
import miMI from "./mi-mi.json"

import { apiErrorCodes } from "~/types/apiErrors"

export default defineI18nConfig(() => ({
  legacy: false,
  messages: {
    "en-GB": enGB,
    "en-US": enGB, // i18n will yell at us for every string about falling back to en-GB if we don't put this
    "mi-MI": miMI,
  },
  fallbackLocale: "en-gb",
}))

const langKeys = Object.keys(enGB.apiErrors)

for (const apiError in apiErrorCodes) {
  if (!langKeys.includes(apiError)) {
    console.warn(`Missing translation for the API error: "${apiError}"`)
  }
}
